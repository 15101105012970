import { validPaths } from "@/utils";
import { translate } from "@/utils/functions";
import { IconType } from "react-icons/lib";
import { LuConstruction, LuLayoutDashboard } from "react-icons/lu";
import { BiBuildingHouse, BiRocket } from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { TbUsers } from "react-icons/tb";
import { useDarkMode } from "@/hooks";
import { MdDashboard } from "react-icons/md";
import { RiDashboard3Line } from "react-icons/ri";
import { PiHandCoins } from "react-icons/pi";

export type SidebarItem = {
  title: string;
  icon: { iconLight: IconType; iconDark: IconType };
  new?: boolean;
  soon?: boolean;
} & (SidebarSingleItem | SidebarMultipleItem);

export type SidebarSingleItem = {
  type: "single";
  path: string;
  title: string;
  new?: boolean;
  soon?: boolean;
  isExternal?: boolean;
};

export type SidebarMultipleItem = {
  type: "multiple";
  items: SidebarSingleItem[];
};

// TODO: WHEN COMPLETE EVERYTHING LAUNCH TO PRODUCTION THE MULTIPLE OPTIONS
/* const validHostnameCRM = ["staging.brokerlit.com", "localhost"]; */

const BUILDDATASIDEBAR = (): SidebarItem[] => {
  return [
    {
      type: "single",
      icon: { iconDark: LuConstruction, iconLight: LuConstruction },
      path: validPaths.builder.path,
      title: "Sitio web",
      soon: true,
    },
  ];
};

// OUTLINE ICONS
export const useDataSidebar = (): SidebarItem[] => {
  const { theme } = useDarkMode();
  const toggleIcon = ({
    iconLight,
    iconDark,
  }: {
    iconLight: IconType;
    iconDark: IconType;
  }) => (theme === "dark" ? iconDark : iconLight);
  toggleIcon({ iconLight: LuLayoutDashboard, iconDark: MdDashboard });
  return [
    {
      title: translate("dashboard.title"),
      icon: { iconDark: RiDashboard3Line, iconLight: RiDashboard3Line },
      type: "single",
      path: validPaths.dashboard.path,
    },
    {
      title: translate("agents.title"),
      icon: { iconDark: TbUsers, iconLight: TbUsers },
      type: "single",
      path: validPaths.agents.path,
    },
    {
      title: "Propiedades",
      type: "multiple",
      icon: { iconDark: BiBuildingHouse, iconLight: BiBuildingHouse },
      items: [
        {
          type: "single",
          path: validPaths.properties.path,
          title: "Listado de propiedades",
        },
        {
          type: "single",
          path: validPaths.propertiesCreate.path,
          title: "Crear propiedad",
        },
      ],
    },
    {
      type: "single",
      path: validPaths.tags.path,
      icon: { iconDark: BsTags, iconLight: BsTags },
      title: "Etiquetas",
    },
    {
      type: "multiple",
      icon: { iconDark: BiRocket, iconLight: BiRocket },
      items: [
        {
          type: "single",
          path: validPaths.teams.path,
          title: "Equipos",
          new: true,
        },
        {
          type: "single",
          path: validPaths.prospects.path,
          title: "Primer filtro",
        },
        {
          type: "single",
          path: validPaths.opportunities.path,
          title: "Oportunidades",
        },
        {
          type: "single",
          path: validPaths.statistics.path,
          title: "Estadísticas",
          new: true,
        },
      ],
      title: "CRM",
      new: true,
    },
    ...BUILDDATASIDEBAR(),
    {
      type: "single",
      path: "https://app.holacasa.mx/ve1wko",
      title: "Crédito hipotecario",
      icon: { iconDark: PiHandCoins, iconLight: PiHandCoins },
      isExternal: true,
      new: true,
    },
  ];
};
